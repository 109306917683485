import mintcard from "../img/mintcard.png";
import remaining from "../img/rb.png";
import whitebox from "../img/white box.png";

import { Box, Stack } from "@mui/material";
import { useState } from "react";

import MintMenu from "./MintMenu";
import PaymentMethods from "./PaymentMethods";

export default function MintCard() {
  var desktopText = (
    <>
      <Box>1. Connect your wallet</Box>
      <Box>2. Input the numbers of NFTs you wish to mint.</Box>
      <Box>
        3. Click one of the "Buy with ..." buttons (Note: NFT are minted on the
        ETH chain regardless of what currency you buy with).
      </Box>
      <Box>
        4. A popup from "crossmint" will show up. Follow the instructions on the
        pop up to complete the purchase.
      </Box>
    </>
  );
  var mobileText = (
    <>
      <Box>1. Connect your wallet</Box>
      <Box>2. Input the numbers of NFTs you wish to mint.</Box>
      <Box>3. Click "Click to Mint" and a 3 options will show up.</Box>
      <Box>4. Click "Buy with ..." for the currency you wish to buy with.</Box>
      <Box>
        5. A popup from "crossmint" will show up. Follow the instructions on the
        pop up to complete the purchase.
      </Box>
    </>
  );

  const [qty, setQty] = useState(1);
  const nftValue = parseFloat(process.env.REACT_APP_NFTPrice);
  const [Totalamount, setTotalAmount] = useState(nftValue);

  const handleInc = async () => {
    let newVal = qty + 1;
    let newAmount = newVal * nftValue;

    setQty(newVal);
    setTotalAmount(newAmount);
  };

  const handleDec = async () => {
    if (qty <= 1) {
      document.getElementById("errorMsg").innerHTML =
        "Error! Minimum Minting Qty is 1.";
      setTimeout(() => {
        document.getElementById("errorMsg").innerHTML = "";
      }, 3000);
      let newVal = 1;
      let newAmount = newVal * nftValue;

      setQty(newVal);
      setTotalAmount(newAmount);
    } else {
      let newVal = qty - 1;
      let newAmount = newVal * 0.1;

      setQty(newVal);
      setTotalAmount(newAmount);
    }
  };

  return (
    <>
      <Box
        className="mintCard"
        width={{ xs: "330px", sm: "390px", lg: "500px" }}
        height={{ xs: "570px", lg: "600px" }}
        pb={-10}
        style={{ position: "relative", zIndex: "5" }}
      >
        <img src={mintcard} width="100%" height="100%" alt="AH" />
        <Box
          style={{
            position: "absolute",
            top: "120px",
            bottom: "120px",
            width: "100%",
          }}
        >
          <Stack alignItems={"center"} justifyContent="center" mt={2}>
            <Stack style={{ fontFamily: "otter", color: "white" }}>
              <a
                href="https://twitter.com/Otters_universe"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                HELP / TWITTER
              </a>
            </Stack>
            <Box
              mt={2}
              padding="0px 15px 0px 35px"
              className="mintPageTitles"
              sx={{
                lineHeight: { xs: "18px", lg: "22px" },
                fontSize: { xs: "13px", lg: "15px" },
              }}
            >
              <Box
                display={{ xs: "none", small: "none", md: "none", lg: "block" }}
              >
                {desktopText}
              </Box>
              <Box
                display={{
                  xs: "block",
                  small: "block",
                  md: "block",
                  lg: "none",
                }}
              >
                {mobileText}
              </Box>
            </Box>
            <div id="errorMsg"></div>

            <Box>
              <Stack
                height={{ xs: "190px", lg: "220px" }}
                width="100%"
                alignItems={"center"}
                mt={{ xs: 3, sm: 3, lg: 2 }}
                style={{
                  position: "relative",
                }}
              >
                <img src={remaining} alt="AH" height="100%" width="90%" />
                <Box
                  style={{
                    position: "absolute",
                    top: "10px",
                    width: "90%",
                  }}
                >
                  <Stack
                    width="100%"
                    direction="row"
                    justifyContent="space-around"
                    className="mintPageTitles"
                  >
                    {/* <Stack alignItems="center">
                      <Box>Remaining</Box>
                      <Box>125</Box>
                    </Stack> */}
                    <Stack alignItems="center">
                      <Box>Price</Box>
                      <Box>{Totalamount.toFixed(3)} ETH</Box>
                    </Stack>{" "}
                    <Stack alignItems="center" justifyContent={"center"}>
                      <Box></Box>
                      <Stack direction="column">
                        {/* <Live /> */}Status
                        <div className="glow"> Live</div>
                      </Stack>
                    </Stack>
                  </Stack>
                  <Stack
                    alignItems="center"
                    mt={2}
                    padding="0px 20px"
                    style={{ position: "relative" }}
                  >
                    <img alt="AH" src={whitebox} width="100%" />

                    <Box
                      style={{
                        position: "absolute",
                        top: "30%",
                        width: "90%",
                      }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent={"space-around"}
                        height="100%"
                      >
                        {/* <Stack alignitems="center">
                                <img src={minus} width="30%" />
                              </Stack> */}
                        <Stack
                          top="-10px"
                          direction="row"
                          alignitems="center"
                          justifyContent="space-around"
                          width="100%"
                          style={{
                            fontFamily: "otter",
                            color: "var(--main)",
                            fontSize: "160%",
                          }}
                        >
                          <Box
                            style={{ cursor: "pointer" }}
                            onClick={handleDec}
                          >
                            -
                          </Box>
                          {qty}
                          <Box
                            onClick={handleInc}
                            style={{ cursor: "pointer" }}
                          >
                            +
                          </Box>
                        </Stack>
                        {/* <Stack alignitems="center">
                                <img src={minus} width="30%" />
                              </Stack> */}
                      </Stack>
                    </Box>
                  </Stack>
                  <Stack
                    alignItems="center"
                    mt={1}
                    justifyContent="center"
                    direction="row"
                    flexWrap="wrap"
                    style={{ cursor: "pointer" }}
                  >
                    {/* <img alt="AH" src={mintbtn} width="70%" /> */}
                    <Box
                      m={"0px 4px"}
                      display={{ xs: "none", sm: "none", lg: "block" }}
                    >
                      <PaymentMethods
                        totalAmount={Totalamount}
                        totalCount={qty}
                      />
                    </Box>
                    <Box display={{ xs: "block", sm: "block", lg: "none" }}>
                      <MintMenu totalAmount={Totalamount} totalCount={qty} />
                    </Box>
                  </Stack>
                </Box>
              </Stack>
            </Box>
          </Stack>
          {/* <Stack alignItems="center" mt={4}>
            <img src={wlp} width="70%" />
          </Stack> */}
        </Box>
      </Box>
    </>
  );
}
