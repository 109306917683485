import { Box, Stack } from "@mui/material";
import bg from "../img/main_logo.png";

export default function Preloader() {
  return (
    <>
      <Stack height="100vh" alignItems="center" justifyContent={"center"}>
        <Box width="200px" className="preloader">
          <img src={bg} alt="loader" width={"100%"} />
        </Box>
      </Stack>
    </>
  );
}
