import { Box, Stack } from "@mui/material";

import MintCard from "../components/MintCard";
import Social from "./Social";
import RightSide from "./RightSide";

export default function Mint() {
  return (
    <>
      <Box className="mintMain" pb={{ xs: "150px", lg: 0 }}>
        <Box variant="header">
          <Stack direction={"row"} mt={1} justifyContent="flex-end" width="95%">
            <Box className="address">
              <span
                style={{
                  color: "var(--red)",
                }}
              >
                Connected
              </span>{" "}
              | 0x4a21245...12sa35
            </Box>
          </Stack>
        </Box>
        <Stack
          direction={{ xs: "column", sm: "row", lg: "row" }}
          width="100%"
          height="80%"
        >
          <RightSide />

          <Stack
            flex="1"
            alignItems="center"
            justifyContent={{ xs: "center", lg: "flex-end" }}
            direction={{ xs: "column", sm: "column", lg: "row" }}
            mr={{ xs: 0, sm: 0, lg: 5 }}
            mt={10}
          >
            <Box
              mt={"50px"}
              ml={-2}
              pb={3}
              width="50%"
              sx={{
                display: { xs: "block", sm: "block", md: "none", lg: "none" },
              }}
            >
              <Social />
            </Box>
            <Box>
              <MintCard />
            </Box>
          </Stack>
        </Stack>
      </Box>
    </>
  );
}
