import { CrossmintPayButton } from "@crossmint/client-sdk-react-ui";
import { Box, Stack } from "@mui/material";

export default function PaymentMethods({ method, totalAmount, totalCount }) {
  // console.log(typeof totalAmount);
  // console.log(process.env.REACT_APP_ENVIR);
  console.log(totalAmount, totalCount);
  return (
    <>
      <Stack
        direction="row"
        flexWrap="wrap"
        alignItems={"center"}
        justifyContent="center"
      >
        <Box className="MintWithButtons">
          <CrossmintPayButton
            className="cutomButton"
            clientId={process.env.REACT_APP_CLIENTID}
            paymentMethod="ETH"
            mintConfig={{
              type: "erc-721",
              totalPrice: String(totalAmount),
              _count: String(totalCount),
            }}
            environment={process.env.REACT_APP_ENVIR}
          />
        </Box>
        <Box className="MintWithButtons">
          <CrossmintPayButton
            className="cutomButton"
            clientId={process.env.REACT_APP_CLIENTID}
            paymentMethod="SOL"
            preferredSigninMethod="solana"
            mintConfig={{
              type: "erc-721",
              totalPrice: String(totalAmount),
              _count: String(totalCount),
            }}
            environment={process.env.REACT_APP_ENVIR}
          />
        </Box>
        <Box className="MintWithButtons">
          <CrossmintPayButton
            className="cutomButton"
            clientId={process.env.REACT_APP_CLIENTID}
            paymentMethod={method}
            mintConfig={{
              type: "erc-721",
              totalPrice: String(totalAmount),
              _count: String(totalCount),
            }}
            environment={process.env.REACT_APP_ENVIR}
          />
        </Box>
      </Stack>
    </>
  );
}
