import { Box, Stack } from "@mui/material";
import Social from "./Social";
export default function RightSide() {
  return (
    <>
      <Stack
        flex={{ xs: "", lg: "1" }}
        alignItems="center"
        justifyContent="center"
      >
        {/* <Box
          sx={{ top: { xs: "40px", lg: "-40px" }, margin: "0px 10px" }}
          style={{
            position: "relative",
            height: "30px",
            cursor: "pointer",
          }}
        >
          <Box
            sx={{
              width: "320px",
              height: "90px",
            }}
          >
            <img
              src={remaining}
              width="100%"
              height="70%"
              alt="main"
              style={{ position: "relative" }}
              className="mint"
            />
          </Box>
          <div className="head05">Connect Wallet</div>
        </Box> */}

        <Box
          display={{ xs: "none", lg: "block" }}
          className="MintH"
          padding={4}
          m={1}
          style={{
            // background: "red",
            border: "1px solid #ccc",
            borderRadius: "20px",
            backdropFilter: "blur(4px)",
            textAlign: "center",
            zIndex: 5,
          }}
        >
          Join the Party
        </Box>
        <Box display={{ xs: "none", lg: "block" }}>
          <Social />
        </Box>
      </Stack>
    </>
  );
}
