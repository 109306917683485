import Mint from "./components/Mint";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import Preloader from "./components/Preloader";
// require("dotenv").config();
function App() {
  const [loading, setLoading] = useState(false);

  //For testing preloader

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <Box
          style={{
            width: "100vw",
            height: "100vh",
            backdropFilter: "blur(4px)",
            zIndex: 5,
            overflowX: "hidden",
          }}
        >
          <Mint />
        </Box>
      )}
    </>
  );
}

export default App;
