import { Box, Stack } from "@mui/material";
import IG from "../img/IG_icon.png";
import DC from "../img/discord_icon.png";
import TW from "../img/twitter_icon.png";

export default function Social() {
  return (
    <Stack
      direction="row"
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <Box className="iconsTop" mt={2}>
        <a href="http://instagram.com/otters_universe">
          <img src={IG} alt="hh" width={20} />
        </a>
      </Box>
      <Box className="iconsTop" mt={2}>
        <a href="http://twitter.com/otters_universe">
          <img src={TW} alt="hh" width={20} />
        </a>
      </Box>
      <Box className="iconsTop" mt={2}>
        <a href="https://discord.gg/MPbB7EjBEW">
          <img src={DC} alt="hh" width={20} />
        </a>
      </Box>
    </Stack>
  );
}
